<template>
  <div class="box-modal-wrapper box-subscribe-modal">
    <div class="container">
      <div class="box-modal-header d-flex justify-content-center align-items-center">
        <button type="button" class="close-modal-btn" @click="closeSubscribeModal">
          <closeIcon/>
        </button>
        <div class="box-modal-header-logo">
          <headerLogo/>
        </div>
      </div>
      <div class="box-modal-body d-flex justify-content-start align-items-center flex-column">
        <p class="box-modal-title">Compre créditos para obter acesso a este treino</p>
        <div class="plan-item-wrapper">
          <div class="plan-item d-flex flex-column justify-content-center align-items-center">
            <div class="plan-item-icon">
              <lock/>
            </div>
            <p class="plan-item-title"></p>
            <p class="plan-item-subtitle">Créditos insuficientes</p>
            <div class="plan-item-price">
              <span class="smaller">comprar desde</span>
              1€
              <span> por 10 creditos</span>
            </div>
            <router-link to="/reserved-area-credits" class="generic-btn red-gradient plan-item-btn">Comprar Créditos</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import lock from "@/components/svg/lock2.vue";
import headerLogo from "@/components/svg/logo.vue";
import closeIcon from "@/components/svg/close.vue";

export default {
  name: "buy-credits-modal",
  components: {
    closeIcon,
    headerLogo,
    lock
  },
  methods: {
    closeSubscribeModal() {
      let element = document.getElementsByClassName("box-subscribe-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      }
    }
  }
}


</script>