<template>
  <div class="dashboard workout-detail">
    <div class="workout-detail-block">
      <div class="workout-detail-top-wrapper">
        <div class="container">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex justify-content-start align-items-center">
              <button type="button" class="heading-back" @click="$router.go(-1)">
                <chevronLeft/>
              </button>
              <p class="workout-detail-top-title d-none d-lg-block">{{ workshopItem.title }}</p>
            </div>
            <div class="d-flex justify-content-end align-items-center">
              <!--<button type="button" class="workout-detail-top-btn">
                <calendar/>
              </button>
              <button type="button" class="workout-detail-top-btn">
                <shareIcon/>
              </button>-->
            </div>
          </div>
        </div>
      </div>
      <div class="workout-detail-content-wrapper">
        <div class="container">
          <div class="workout-detail-content">
            <div class="row">
              <div class="col-12 col-lg-7">
                <div  v-if="!!workshopItem.media">
                  <div class="workout-detail-image" v-if="!!workshopItem.media.general" v-on="!!workshopItem.media.videos ? { click: toggleVideoModal } : {}" :class = "( !!workshopItem.media.videos ) ? 'has-video' : '' ">
                    <img :src="workshopItem.media.general[0].image" alt="">
                    <div class="workout-detail-image-content">
                      <div class="container">
                        <div class="d-flex justify-content-start align-items-end">
                          <div class="workout-detail-video-btn">
                            <playIcon v-if="hasBought" />
                            <lock class="lock" v-else />
                          </div>
                          <div class="d-lg-none">
                            <p class="workout-detail-title">{{ workshopItem.title }}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div class="col-12 col-lg-5">
                <div class="workout-info">
                  <div class="d-flex container">
                    <div class="workout-info-item d-flex justify-content-center align-items-center flex-column">
                      <div class="workout-info-item-icon d-none d-lg-block">
                        <clock/>
                      </div>
                      <p class="workout-info-item-title">{{ workshopItem.duration }}</p>
                      <p class="workout-info-item-subtitle">Duração</p>
                    </div>
                    <div class="workout-info-item d-flex justify-content-center align-items-center flex-column">
                      <div class="workout-info-item-icon d-none d-lg-block">
                        <notes/>
                      </div>
                      <p class="workout-info-item-title">{{ workshopItem.theme }}</p>
                      <p class="workout-info-item-subtitle">Tema</p>
                    </div>
                  </div>
                </div>
                <div class="workout-author-info">
                  <div class="container d-flex justify-content-between align-items-end">
                    <div>
                      <p class="workout-author-info-title">Workshop Criado por</p>
                      <p class="workout-author-info-name">{{ workshopItem.createdBy }}</p>
                    </div>
                    <div>
                      <router-link :to="{ name: 'author-detail', params:{id:workshopItem.createdById} }" class="workout-author-info-link">Ver perfil</router-link>
                    </div>
                  </div>
                </div>
                <div class="workout-detail-text-wrapper" v-if="workshopItem.description">
                  <div class="container">
                    <p class="workout-detail-text-title">Descrição do Workshop</p>
                    <div class="workout-detail-text-description-wrapper">
                      <div class="workout-detail-text-description" v-html="workshopItem.description"></div>
                      <div class="workout-detail-text-description-btn-wrapper d-flex justify-content-center align-items-center">
                        <button type="button" class="workout-detail-text-description-btn d-flex justify-content-center align-items-center" @click="toggleTextHeight">
                          <p>Ler Mais</p>
                          <span class="workout-detail-text-description-btn-icon">
                          <chevronDown/>
                        </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div v-if="!!workshopItem.media" class="d-none d-lg-block">
                  <div class="workout-detail-btn-wrapper" v-if="!!workshopItem.media.videos">
                    <button type="button" class="generic-btn red-gradient workout-detail-btn" v-if="!hasBought" v-on="!!workshopItem.media.videos ? { click: toggleVideoModal } : { click: toggleCreditsModal }">{{ startWorkoutBtnTxt + workshopItem.price+' creditos'}}</button>
                    <button type="button" class="generic-btn red-gradient workout-detail-btn"
                            v-if="hasBought" @click="toggleVideoModal">Iniciar
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <equipmentBlock :parentItem="workshopItem" :equipment="workshopItem.equipments" v-if="workshopItem.equipments" />
    <div v-if="!!workshopItem.media">
      <div class="workout-detail-btn-wrapper d-lg-none" v-if="!!workshopItem.media.videos">
        <div class="container d-flex justify-content-center align-items-center">
          <button type="button" class="generic-btn red-gradient workout-detail-btn" v-if="!hasBought">
            {{ startWorkoutBtnTxt }}
          </button>
          <button type="button" class="generic-btn red-gradient workout-detail-btn" @click="toggleVideoModal">
            Iniciar Workshop
          </button>
        </div>
      </div>
    </div>

    <videoModal :video="workshopItem.media.videos[0]" :key="videoModalkey" v-if="!!workshopItem.media.videos" :productID="workshopItem.id" />
    <creditsModal/>
  </div>
</template>

<script>
import equipmentBlock from "@/components/equipment/equipment-block.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import bookmark from "@/components/svg/bookmark.vue";
import shareIcon from "@/components/svg/share-icon.vue";
import calendar from "@/components/svg/calendar.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import notes from "@/components/svg/notes.vue";
import lock from "@/components/svg/lock2.vue";
import chevronDown from "@/components/svg/chevron-down.vue";
import playIcon from "@/components/svg/play.vue";
import videoModal from "@/components/modals/video-modal.vue";
import creditsModal from "@/components/modals/credits-buy-modal.vue";
import {EventBus} from "@/event-bus";
import axios from "axios";
import qs from "qs";

export default {
  name: "workshop-detail",
  data () {
    return {
      errors: [],
      lang: this.$root.siteLang,
      workshopItem: [],
      videoModalkey: 0,
      startWorkoutBtnTxt: 'Comprar por ',
      hasBought: false,
    }
  },
  components : {
    equipmentBlock,
    chevronLeft,
    bookmark,
    shareIcon,
    calendar,
    clock,
    level,
    notes,
    chevronDown,
    playIcon,
    videoModal,
    lock,
    creditsModal
  },
  methods: {
    toggleTextHeight() {
      let element = document.getElementsByClassName("workout-detail-text-description-wrapper")[0];

      if ( !element.classList.contains("open") ) {
        element.classList.add("open");
      }
    },
    toggleVideoModal() {
      if ( !this.hasBought ) {
        let authKey = window.$cookies.get("authKey");

        const requestBody = {
          product: this.workshopItem.id,
        };

        axios
            .post(process.env.VUE_APP_API_URL + 'products/buy', qs.stringify(requestBody),{
              headers: {
                Authorization: 'Bearer ' + authKey
              }
            })
            .then((response) => {
              if ( response.data.errors != undefined ) {
                this.$toast.error(response.data.errors, {position: 'bottom-right', hideProgressBar: true});
              } else {
                this.hasBought = true;
                this.playVideo();
              }
            })
            .catch((e) => {
              if(this.$root.userInfo.credits < this.workshopItem.price ) {
                this.toggleCreditsModal()
              }
              this.$toast.error(e.response.data.errors, {position: 'bottom-right', hideProgressBar: true});
            });
      } else {
        this.playVideo();
      }
    },
    toggleCreditsModal() {
      let element = document.getElementsByClassName("box-subscribe-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    playVideo() {
      let element = document.getElementsByClassName("box-video-modal")[0];

      if ( element.classList.contains("open") ) {

        element.classList.remove("open");

        if ( this.showFeedback === true ) {
          document.getElementsByClassName("box-feedback-modal")[0].classList.add("open");
        }
      } else {
        element.classList.add("open");
        EventBus.$emit("bus-playVideo");
      }
    }
  },
  mounted() {
    EventBus.$on('bus-toggleVideoModal', () => {
      let element = document.getElementsByClassName("box-video-modal")[0];

      if ( element.classList.contains("open") ) {
        element.classList.remove("open");

        if ( this.showFeedback === true ) {
          document.getElementsByClassName("box-feedback-modal")[0].classList.add("open");
        }
      } else {
        element.classList.add("open");
        EventBus.$emit("bus-playVideo");
        this.videoModalkey++;
      }
    });
  },
  created() {
    const areaSlugId = this.$route.params.id;
    const areaSlugParts = areaSlugId.split('-');
    const idAreaItem = areaSlugParts[areaSlugParts.length - 1];

    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'products/'+ idAreaItem + '?id_category=2&with_category_title=0&lang=' + this.lang)
        .then((response) => {
          this.workshopItem = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});

    axios
        .get(process.env.VUE_APP_API_URL + 'products/buy?product='+ idAreaItem +'&lang=' + this.lang, {
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.hasBought = response.data.model.exists;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {});
  },
}
</script>